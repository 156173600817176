/* Import fonts */
@import url('https://fonts.googleapis.com/css2?family=Gantari:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');

/* PrimeReact Theme */
@import 'primereact/resources/themes/lara-light-blue/theme.css';

/* Tailwind Layers */
@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}


body {
  font-family: Gantari;
}

/* Loader.css */
.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.shadow-right {
  box-shadow: 4px 0 10px -2px rgba(0, 0, 0, 0.3);
}

.custom-width-search {
  width: clamp(15.625rem, 1.4238rem + 29.5858vw, 28.125rem);
}

.custom-page-heading {
  @apply text-[22px] font-semibold leading-[24px] font-gantari;
}

.custom-body-semibold {
  @apply text-[16px] font-semibold leading-[24px] font-gantari text-[#019DF7];
}

.custom-body3-semibold {
  @apply text-[14px] font-semibold leading-[21px] font-gantari text-[#3E4044];
}

.custom-body-regular {
  @apply text-[14px] font-normal leading-[21px] font-gantari text-[#6C6C6C];

}

.custom-edit-button {
  @apply flex flex-row custom-body-semibold cursor-pointer gap-[4px];
}

.screen-size {
  @apply max-w-screen-1440;
  @apply mx-auto;
}

.rs-steps-item-title {
  font-size: 14px;
  font-weight: 500;
  color: #3E4044;
}

.rs-steps-item-description {
  font-size: 12px;
  color: #808080;
  font-weight: 400;
  margin-top: 5px !important;
}

.text-gray-900 {
  color: white !important;
}

.dark\:text-gray-900 {
  color: white !important;
}

.transparent-button {
  background-color: transparent !important;
  color: #999999 !important;
  border: 2px solid #999999 !important;
  padding: 10px 40px;
}

.color-transparent-button {
  background-color: transparent !important;
  color: #019DF7 !important;
  border: 1px solid #019DF7 !important;
  height: 53px !important;
  /* padding: 10px 40px; */
}

button:focus {
  --tw-shadow: none !important;
  /* --tw-border-opacity: 0 !important; */
}

.custom-header-bg {
  background-color: #E7F6FE !important;
  font-size: 14px;
  font-weight: 400;
}

.custom-col-bg {
  background-color: #FFFFFF !important;
  font-size: 14px;
  font-weight: 400;
  color: black;
}

.input-label-settings {
  @apply text-[14px] font-normal leading-[24px] font-gantari text-[#3E4044] px-2 gap-[10px];
}

.settings-section-heading {
  @apply text-[18px] font-medium font-gantari leading-[27px] text-[#3E4044];
}

.modal-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* .custom-dialog-footer {
  @apply text-[red];
} */
[data-pc-section="closebuttonicon"] {
  justify-content: flex-end;
}

[data-pc-section="footer"] {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: #F5F5F5 !important;
  border-top: 1px solid #ddd;
}

[data-pc-name="paginator"] {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

[data-pc-section="pagebutton"][aria-current="true"] {
  background-color: #019DF7 !important;
  color: white !important;
}

[data-pc-section="headerrow"] th:first-child {
  border-top-left-radius: 4px;
}

[data-pc-section="headerrow"] th:last-child {
  border-top-right-radius: 4px;
}

[data-pc-section="headertitle"] {
  color: #3E4044;
}

[data-pc-section="bodycell"]:not([style*="color"]) {
  color: #6C6C6C !important;
}

[data-pc-section="bodyrow"] {
  border-bottom: 2px solid #E7E7E7;
}

[data-pc-section="bodyrow"]:focus {
  border-bottom: 2px solid #E7E7E7;
}

[data-pc-section="slider"][aria-checked="true"] {
  background-color: #019DF7;
}

select {
  height: 50px;
  border-radius: 4px;
}

/* [data-pc-section="root"] {
  max-height: max-content !important;
} */

label span.required_star {
  color: red;
  font-size: inherit;
  vertical-align: middle;
}

@media (prefers-color-scheme: dark) {
  .dark\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity)) !important;
  }
}

@media (prefers-color-scheme: dark) {
  .before\:dark\:bg-gray-900::before {
    background-color: white !important;
  }
}

@media (prefers-color-scheme: dark) {

  .custom-header-bg {
    background-color: #E7F6FE !important;
    color: inherit !important;
  }

  .custom-dialog-bg {
    background-color: #FFFFFF !important;
    color: inherit !important;
  }

  .custom-col-bg {
    background-color: #FFFFFF;
    color: inherit !important;
  }

  .dark\:text-white\/80 {
    color: inherit !important;
  }

  /* .dark\:text-white\/80 {
    background-color: inherit !important;
  } */

  .custom-header-bg .dark\:bg-gray-900 {
    background-color: unset !important;
  }

  .custom-dialog-bg .dark\:bg-gray-900:not([data-pc-section="footer"]) {
    background-color: unset !important;
  }

  .dark\:border-blue-900\/40 {
    border-color: inherit !important;
  }

  .dark\:text-white\/70 {
    color: inherit !important;
  }

  [data-pc-name="paginator"].dark\:bg-gray-900 {
    background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
  }

  .dark\:text-white {
    color: rgb(107 114 128 / var(--tw-text-opacity)) !important;
  }
}

.settings-modal-button {
  @apply flex justify-end items-center gap-4 pt-4;
}

.settings-modal-cancel-button {
  @apply px-4 py-2 rounded-[4px] border-black border-[1px];
}

.settings-modal-success-button {
  @apply px-4 py-2 text-center rounded-[4px] text-white bg-[#2196F3] hover:bg-[#2196F3] focus:ring-4 focus:outline-none focus:ring-[#2196F3] dark:bg-[#2196F3] dark:hover:bg-[#2196F3] dark:focus:ring-[#2196F3];
}

.settings-modal-text {
  @apply pt-[12px] text-[24px] font-[700] leading-[28.8px] text-center;
}

input[type="password"]::-ms-reveal {
  display: none;
}

/* Optional: For older versions of Edge (legacy engine) */
input[type="password"]::-ms-clear {
  display: none;
}